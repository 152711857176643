/* =======frequently asked question========= */

.faq {
  background: var(--color-gray-500);
  padding: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}

.faq div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq__icon{
  background: transparent; /*this will make the + sign button go dark so that we can change the color */
  color: var(--color-gray-100);
  font-size: 1.5rem;

}

.faq p {
  margin-top: 1.5rem;
}
