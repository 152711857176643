.donationHeader {
  /* background-color: rgb(4, 57, 19); */
  background-color: black;
  text-align: center;
  margin-top: 0;
  padding: 100px;
}

.donationHeader h1 {
  font-size: 65px;
  color: #ffffff;
  font-weight: bold;
}

.donationHeader p {
  font-size: 14px;
  color: #ffffff;
}
.donationHeader small {
  font-size: 12px;
  color: #ffffff;
  border: 1px solid white;
  padding: 3px;
}





/* .donation__container{
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 gap: 3rem;
} */

.donations h3{
  margin-bottom: 0.2rem;
}

.donations small{
  margin-bottom: 2rem;
}


.donateCard{
  margin: 2rem;
  border-color: var(--color-primary);
}


/* =====MEDIA QUERY FOR TABLETS */
@media screen and (max-width:1024px){
  .donation__container{
    grid-template-columns:  1fr;
  }

}


/* =====MEDIA QUERY FOR MOBILES */
@media screen and (max-width:600px) {

.donationHeader {
    /* background-color: rgb(4, 57, 19); */
    background-color: black;
    text-align: center;
    margin-top: 90px;
    padding: 10px;
  }

  .donationHeader h1 {
    font-size: 25px;
    color: #ffffff;
    font-weight: bold;
  }

    .donationHeader p {
      font-size: 8px;
      color: #ffffff;
      text-align: center;
    }

    .donationHeader small {
      font-size: 6px;
      color: #ffffff;
      border: 1px solid white;
      padding: 3px;
    }

  .donation__container {
      grid-template-columns: 1fr;
    }

    .donations{
      width: 100%;
      margin: 0 auto;
    }

    .card h3{
      font-size: 16px;
    }

    .donateCard{
      width: 100%;
      margin: 1rem auto;
      margin-bottom: 2rem;
    }
}
