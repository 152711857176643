/* ======FOOTER========== */

footer{
  background:var(--color-main-navbar);
  margin-top: 0rem;
  padding-top: 7rem;
  font-size: 0.9rem;
  color: var(--color-gray-100);
}

.footer__container{
  display: grid;
  grid-template-columns: 26rem 1fr 1fr 1fr; /*the first portion of the grid columns is 26rem, the rest is 1fr 1fr 1fr */
  gap: 6rem;
}

.footer__container article{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
}

.footer__container p{
  margin-top: 0.5rem;
}

.footer__container article h4{
  margin-bottom: 0.6rem;
  font-size: 1.2rem;

}

.footer__socials{
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.5rem;
}

.footer__socials a {
  background: var(--color-gray-100);
  padding: 0.7rem;
  border: 1px solid transparent;
  border-radius: 0.6rem;
  transition: var(--transition);
}

.footer__socials a svg{
  color: var(--color-gray-600);
}

.footer__socials a:hover{
  border-color: var(--color-gray-100);
  background: transparent;
}

.footer__copyright{
  color: #fff;
  text-align: center;
  padding: 0.5rem 0;
  border-top: 1px solid var(--color-gray-100);
  margin-top: 5rem;
}

.copyright {
  text-align: center;
  font-size: 10px;
  background-color: rgb(246, 232, 232);
  color: rgb(153, 47, 47);
  margin: 1px;
}

.copyright a {
  text-decoration: none;
  color: red;
  margin: 5px;
}

.copyright p {
  color: rgb(1, 1, 19);

}


/* ===========MEDIA QUERY FOR FOOTER Tablet  */

@media screen and (max-width: 1024px) {
  .footer__container {
    grid-template-columns: 1fr 1fr ;
    gap: 4rem;
  }
}

/* ===========MEDIA QUERY FOR FOOTER Mobile  */

@media screen and (max-width: 600px) {
  footer{
    margin-top: 7rem;
  }

  .footer__container{
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .footer__container article{
    align-items: center;
  }

  .footer__container p{
    text-align: center;
  }
}
