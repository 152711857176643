/* ===VALUES==== */

 .values__image img {
  width: 450px;
  height: 700px;
  border-radius: 5px;
 margin: auto;
}

.values{
  border-top: 1px solid rgb(235, 233, 233);
  background: rgb(223, 193, 72);
  margin-top: 0rem;
}

 .values__image {
  /* filter: saturate(0.25); this would make the img black and white 25% saturation so that we can add the effect of it being colored */
  /* transition: var(--transition); */
 }

 /* .values__image:hover {
  filter: saturate(1); /*1 is exactly 100% saturation
 } */

.values__container {
  display: grid;
  grid-template-columns: 48% 48%; /*grid template column 38% left and 50% right the rest 12% will go to the gap*/
  gap: 4%;
}


.values__right  p{
  margin: 1.5rem 0 1rem;
}

.values__wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 2.4rem;
}

.values__value {
  padding: 0.5rem;
  /* text-align: left; */
  position: relative;

}

.values__left{
  display: flex;
  justify-content: center;
  align-items: center;
}



/*this will place the icon halfway to the top of the values card */
.values__value span {
  position: absolute; /*  we are positioning the span absolute because we want it to appear on the top left side of the card*/
  top: -1.5rem; /*this will put the icon slightly above the card s */
}


/* ====MEDIA QUERY VALUE SECTION for tablet */

@media screen and (max-width: 1024px){
  .values__container{
    grid-template-columns:  1fr;
    justify-content: center;
    gap: 4rem;
  }

  .values__wrapper{
    gap: 4rem 3rem; /*this properties are 4rem for row gap and 3 for column gap*/
  }

  .values__image{
    display: none;/*this would remove the image for values on small screens*/
  }

}

/* ====MEDIA QUERY VALUE SECTION for tablet */
@media screen and (max-width: 1024px){
  .values__wrapper{
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .values__value{
    width: 84%;
    margin: 0 auto;
  }
}
