/* =====TEAMS SLIDE STYLES===== */

.teams{
  /* width: 50rem; */
  margin-inline: auto; /*this is the same as margin: 0 aut*/
  background-color: rgb(223, 193, 72);
  /* border: 1px solid rgb(201, 199, 199); */
  border-radius: 5px;
  margin-top: 0;
  
}

.teams__head {
  justify-content: center;
}

.card.team{
  position: relative;
  text-align: center;
  margin-top: 5rem ;
  border: 1px solid whitesmoke;
}

.team__avatar{
  width: 4rem;
  height: 4rem;
  /* border-radius: 1.5rem; */
  border-radius: 50%;
  border: 2px solid transparent;
  border-color: whitesmoke;
  overflow: hidden;
  position: absolute;
  top: -2.5rem;
  left: calc(50% - 2rem); /*this is 50% - half of the full width which is 4rem*/
  box-shadow: 0 1.5rem 2rem rgba(26, 26, 54, 0.4);
  transition: var(--transition);

}

/* .team:hover .team__avatar {
  border-radius: 50%;
  border-color: var(--color-gray-400);
} */

.team__quote {
  font-style: italic;
  margin: 1rem  2rem ;
  font-size: 18px;

}

article.card.team h3{
  color: rgb(172, 83, 83);
  margin-top: 3.5rem;
}

.card small.team__title{
  margin: 1.3rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.teams__btn-container{
 width: fit-content;
 margin: 2.5rem auto 0 ;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 2rem;
}

/* teams__btn is for the arrow buttons that navigate the slider left or right  */
.teams__btn{
  background: transparent;
  cursor: pointer;
}

.teams__btn svg{
  fill: rgb(37, 19, 19);;
  font-size: 1.8rem;
  width: 2rem;
  height: 2rem;
}


/* ===========MEDIA QUERY FOR TEAM MEMBERS TABLET*/

@media screen and (max-width: 1024px) {
  .teams{
      width: 75%;
  }
}

/* ===========MEDIA QUERY FOR TEAM MEMBERS MOBILE */

@media screen and (max-width: 600px) {
  .teams{
      width: 100%;
  }
}
