/* .about__section-image img{
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 10px;
} */

 .about__section-image{
   border-radius: 15px;
   overflow: hidden;
   /* transform: skew(15deg); this will make the image to bend and have an oval shape */
   transition: var(--transition);
 }

 /* .about__section-image:nth-child(2){
   transform: skew(-15deg); this is the  image 2 which is the vision image.it would bend towards the right side. It's nth child bc all of the images have the same class
 } */

 .about__section-image:hover{
  transform: skew(0); /*this would get the image from oval to normal when you hover on it*/
 }

 .about__section-content h1{
  margin-bottom: 2rem;
 }

 .about__section-content p{
  margin-bottom: 1rem;
  font-size: 16px;

 }

 /* .aboutHeader p{
 color: red;
 } */

 /* .header__content p {
   color: #eee;
   font-size: 16px;
 } */


 .about__story-container, .about__mission-container{
  display: grid;
  grid-template-columns: 40% 50%; /*40 is for the content and 50 is for the image. then we would give the gap 10%*/
  gap: 10%;
 }

 .about__vision-container {
  display: grid;
  grid-template-columns: 50% 40%;
  gap: 10%;
 }


 /* ===MEDIA QUERY ABOUT PAGE TABLET===*/

 @media screen and (max-width: 1024px){
  .about__section-image{
    width: 60%;
  }

  .about__section-content h1{
    margin-bottom: 1.2rem;
  }

  .about__story-container,
  .about__vision-container,
  .about__mission-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .about__vision .about__section-image{
    grid-row: 1; /*this would make the image of the vision come ontop of the content else it would be at the bottom*/
  }
 }



 /* ===MEDIA QUERY ABOUT PAGE MOBILE===*/


 @media screen and (max-width: 600px) {
  .about__section-image{
    width: 80%;
    margin: 0 auto;
  }

    .about__story-container,
    .about__vision-container,
    .about__mission-container {
      gap: 2rem;
    }

 }
