.gallery{
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
  margin: 2rem;
}

.gallery .pics{
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .pics:hover{
  filter: opacity(.7);
}



/* =====model=================== */
.model{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open{
  visibility: visible;
  opacity: 1;
  transform: scale(1);

}

.model img{
  width: auto;
  max-width: 80%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open svg{
  position: fixed;
  top: 30px;
  right: 40px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: black;
  color: white;
  cursor: pointer;

}


/* =====================Media query======================  */

@media (max-width:991px){
  .gallery{
    /* this will split the screen into 2 when screen < 991px  */
    -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      padding: 0 12px;
  }
}

@media (max-width:480px) {
  .gallery {
      /* this will turn  the screen into 1 column when screen < 480px  */
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
      padding: 0 12px;
    }

}
