.programs__program img {
  width: 100%;
  margin: 0 auto;

}

.programs{
  margin-top: 7rem;
  border-top: 1px solid rgb(241, 235, 235);
  background-color: rgb(244, 234, 234);
}

.programs__wrapper{
  display: grid;
  grid-template-columns: repeat(4, 1fr) ;
  gap: 1%;
  margin-top: 4rem;
  /* width: 800px;
  margin: 0 auto; */
  /* opacity: 0;
    animation: fadeInUp 2s 1s;
    animation-fill-mode: forwards; */
}

/*
 */

.programs__program a{
  display: flex;
  margin-top: 1.5rem;
  align-items: center;  /* this will align the text and icon to the center  */
  justify-content: center;

}

/* .programs__program:hover a{
 background: var(--color-secondary);

} */

.btn-service {
  width: fit-content;
  margin: 0 auto;
  padding: 0 3rem;
  background: transparent;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid var(--color-H3);
}

.btn-service a{
  color: var(--color-H3);
}
.btn-service a:hover{
 color: white;
}

.btn-service:hover {
  color: var(--color-gray-600);
  background: var(--color-main-navbar);
  border: none;
}



/* ==========MEDIA QUERY for tablet  Programs ================== */
@media screen and (max-width: 1024px) {
  .programs {
      margin-top: 10rem;
    }
  .programs__wrapper {
    grid-template-columns: 1fr 1fr;
  }

}


/* ===========MEDIA QUERY FOR Programs Mobile  */

@media screen and (max-width: 600px){



  .programs__wrapper {
      grid-template-columns: 1fr;
      gap: 2rem;
      margin-top: 3rem;
    }

    .programs__program{
      width: 84%;
      margin: 0 auto;
    }


}
