.contactHeader{
  /* background-color: rgb(4, 57, 19); */
  background-color: black;
  text-align: center;
  margin-top: 90px;
  padding: 10px;
  /* opacity: 0;
  animation: fadeInUp 1s 1s;
  animation-fill-mode: forwards; */
}



.contactHeader h1 {
    font-size: 45px;
    color: #ffffff;
    margin-top: 20px;
}

.contactHeader p {
    font-size: 18px;
    color: #ffffff;
}

.mapContainer {
  width: 100%;
  will-change: contents;
  /* height: 90%; */
  /* min-height: 566px; */
  /* position: absolute; */
  /* opacity: 0; */
  /* logo start off invisible for the animate effect to show*/
  top: 5%;
  margin: 0 auto;
  /* z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards; */
  /*this is from animate.css , it will fade in for a second forward*/
  /* animation-delay: 2s; */
  margin-top: 100px;

}


.contact-form{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 50px;
  margin: 0  auto;
  padding: 4rem;
  margin-top: -140px;
}

 .contact-form ul {
  padding: 0;
  margin: 0;
}

.contact-form li {
  padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block; /*display mode is block*/
    position: relative;
    /* opacity: 0; */
    /*animation: fadeInUp 1s 3s; 2s animation,
      2s delay*/
  /* animation-fill-mode: forwards; this will make the form to stay on the page after fadein*/
    clear: both;
}

.contact-form .half-row{
  width: 49%;
  margin-left: 2%;
  float: left;
  clear: none;
}

.contact-form li:nth-child(1){
  margin-left: 0;
}

.contact-form input[type='text'],
input[type='email'] {
  width: 100%;
    border: 1px solid rgb(140, 134, 134);
    height: 50px;
    font-size: 15px;
    color: rgb(39, 34, 34);
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 5px;

}

.contact-form textarea{
  width: 100%;
    border: 1px solid rgb(140, 134, 134);
    height: 50px;
    font-size: 16px;
    color: rgb(39, 34, 34);
    padding: 20px;
    min-height: 150px;
    border-radius: 5px;
    box-sizing: border-box;
}

.flat-button {
  color: #3b3827;
  background: 0 0;
  font: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid #4b4a43;
  float: left;
  border-radius: 4px;
  margin-top: 5rem;
}


/* =====css to toggle the message successful sent pop up */

.msg {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 340px;
  padding: 3px;
  color: rgb(15, 104, 12);
  background-color: #fff;
  border-radius: 5px;
  display: none;
}

.msgAppear {
  display: block;
  /*this will toggle message sent */
}



/* ====css for the map  */


.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 100%;
  border: 1px solid rgb(140, 134, 134);
  border-radius: 5px;

}

iframe.googleMap {
  width: 100%;
  height: 100%;
  position: relative;
  /* opacity: 0; */
  border-radius: 15px;
  /* animation: backInRight 1s 1s; */
  /* animation: fadeInUp 1s 1s; */
  /* animation-fill-mode: forwards; so that it will stay on page*/
}



/* ======css for icons====== */

/* .contact{
  background: green;
  margin-bottom: 150px;
} */
.contact__container{
  display: grid;
  place-items: center;
  /* background: red; */
  position: relative;
  top: 100%;
  width: 100%;
  margin-top: 80px;
}

.contact__wrapper{
  /* width: fit-content; */
  display: flex;
  gap: 2rem;
}

.contact__wrapper a{
  /* background: var(--color-secondary); */
  /* color: #6e6d67; */
  font-size: 2.5rem;
  transition: var(--transition);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.contact__wrapper a.email{
  color: red;
}
.contact__wrapper a.facebook{
  color: #3c45e4;
}
.contact__wrapper a.whatsapp{
  color: #25D366;
}

.contact__wrapper a:hover{
  border: 1px solid rgb(140, 134, 134);
  /* */
}

/* =====MEDIA QUERY CONTACT FORM for medium screens */

@media screen and (max-width:1024px){

  .contact-form {
      width: 80%;
      grid-template-columns: 1fr ;
      gap: 50px;
      margin: 0 auto;
      padding: 4rem;
      margin-top: -140px;
    }
    .contact__container{
        position: relative;
        /* top: 120%; */
    }

  .contact__wrapper{
    gap: 1.5rem;
  }

  .contact__wrapper a{
    padding: 1rem;
    font-size: 3.2rem;
    border-radius: 1rem;
  }

     /* .map-wrap {
       padding-bottom: 50% !important;
     } */

            /* iframe.googleMap {
              padding-bottom: 50% !important;
            } */
}


/* =====MEDIA QUERY CONTACT FORM for only small screens */
@media screen and (max-width:600px){
  .contactHeader{
    padding: 80px;
    position: relative;


  }

  .contactHeader h1{

    font-size: 25px;
    position: absolute;
    bottom: 25px;
    right: 100px;
    /* transform: translateY(-100%); */
  }
  .contactHeader p{
    display: none;
  }

  .contact-form{
      padding: 20px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: -70px;
  }


  .contact__wrapper{
    gap: 1.5rem;
  }

  .contact__wrapper a{
    padding: 1rem;
    /* font-size: 1.2rem; */
    border-radius: 1rem;
  }

  .map-wrap{
    padding-bottom: 0% !important;
        height: 0%;
  }

    iframe.googleMap{
      padding-bottom: 0% !important;
      height: 0%;
    }
}
/* ====================screens <350============= */
@media screen and (max-width:380px) {
  .contactHeader {
    padding: 80px;
    position: relative;


  }

  .contactHeader h1 {

    font-size: 25px;
    position: absolute;
    bottom: 25px;
    right: 100px;
    /* transform: translateY(-100%); */
  }

  .contactHeader p {
    display: none;
  }

  .contact-form {
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -70px;
  }


  .contact__wrapper {
    gap: 1.5rem;
  }

  .contact__wrapper a {
    padding: 1rem;
    /* font-size: 1.2rem; */
    border-radius: 1rem;
  }

  .map-wrap {
    padding-bottom: 0% !important;

  }

  iframe.googleMap {
    padding-bottom: 0% !important;

  }
}
