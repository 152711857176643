/* the main header wraps all the content in our landing page */
.main__header-image img{
  width: 650px;
  border-radius: 15px;
  height: 350px;
  /*this position relative sends the picture infront of the yellow sciecle at header*/
  /* transform: rotate(20deg); */
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 2.9;
}

.main__header{
width: 100vw;
height: 100vh;/*we are subtracting the margin-top of 3rem from 100vh which i gave to the main-header  */
display: grid; /*we display grid when we want to put item  in the center */
place-items: center;
margin-top: 6rem;
background-image:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url('../../Images/family1.png');
/* background: rgba(0, 0, 0, 0.8); */


  background-color: #322c2c;
  /* Used if the image is unavailable */
  /* height: 500px; */
  /* You must set a specified height */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

.main__header-container {
  width: 100%;
  height: 100%;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  position: absolute; */

  display: flex;
    justify-content: center;
    align-items: center;

}

.main__header-left{
  /*make the header text go up closer to the nav by -3rem*/
  text-align: center;
  /* opacity: 0;
  animation: fadeInUp 1s 1s;
  animation-fill-mode: forwards; */
}

.main__header-left h2{
  font-size: 70px;
  margin-bottom: 2rem;
}

.main__header-left h4{
  margin-bottom: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
  opacity: 0;
  animation: fadeInUp 1s 1s;
  animation-fill-mode: forwards;
}

.main__header-left p{
  margin: 1rem  0 2.5rem;
  font-size: 1.1rem;
  color: rgb(255, 255, 255);

  opacity: 0;/*this will use animate.css to make the element transparent*/
  animation: fadeInUp 1s 0s; /*this will use animate.css animate th element to show up*/
  animation-fill-mode: forwards; /*this will use animate.css to make element stay on the page after the animation*/

}

a.readMe{
  /* width: fit-content; */
    margin: 0 auto;
    padding: 1rem 2rem;
    background: transparent;
    border-radius: 10px;
    font-size: 16px;
    border: 1px solid grey;
}

/* .main__header-right{
  display: grid;
  place-items: center; */
  /* position: relative;/*this would help contain any absolute element we position to the right */
  /* opacity: 0;
  animation: fadeInUp 2s 1s;
  animation-fill-mode: forwards;
} */

.main__header-left span.text1{
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 150px;
  letter-spacing: 5px;
  position: relative;
  /* background: white; */
  animation: text1 3s 1 ease forwards;

}
.main__header-left span.text2{
  color: #fff;
  font-size:45px;
  font-weight: 700;
  letter-spacing: 7px;
  animation: text2 5s 1 ease forwards;
}

@keyframes  text1{
  0%{
    color:white;
    font-size: 45px;
    letter-spacing: 3px;
  }
}

@keyframes  text2{
  0%{
    color:white;
    font-size: 45px;
    letter-spacing: 5px;

  }
}
  /* 20%{
    color: rgb(36, 161, 90);
    letter-spacing: 5px;
      font-size: 28px;
  } */
  /* 80%{
    color: black;
    letter-spacing: 7px;
      font-size: 28px;
  } */
  /* 100%{
    color:red;
    letter-spacing: 5px;
  } */
/* } */

.quotes{
  color: var(--color-secondary);
  margin: 5px;
  font-size: 20px;
}

/* .main__header-circle{
  width: 32rem;
  height: 32rem;
  background: linear-gradient(75deg, var(--color-secondary), transparent);
  border-radius: 50%;
  position:absolute;this is why we gave the main header rifht position of relative so that the element withing it can be put in the middle. */
  /* transition: var(--transition);
filter: blur(15px);
} */

/* .main__header:hover .main__header-circle{
  filter: blur(85px);
} */



/* media query MAIN HEADER SECTION for tablet  */

@media screen and (max-width: 1024px) {
  .main__header-image img{
    width: 350px;
    height: 198px;
    margin: 0 auto;
  }
  .main__header{
    height: fit-content; /*we can also set it to auto and it will still work*/
    padding: 14rem 0;
    margin-top: 12rem;
  }

     .main__header h4 {
       font-size: 12px;
     }

  .main__header-container{
    gap: 0;
  }

  .main__header-circle{
    width: 18rem;
    height: 18rem;
  }
}


/* ===========MEDIA QUERY FOR FOOTER Mobile  */

@media screen and (max-width: 650px) {
  .main__header{
    margin-top: 0;
    padding: 12rem 0 0;
    height: 100vh;
  }

   .main__header h4{
    font-size: 12px;
  }
.main__header-image, .main__header-circle{
  display: none;
}

.main__header-container{
  grid-template-columns: 1fr;
}

.main__header-left h2 {
  font-size: 18px;
}

.main__header-left h1 {
  line-height: 40px;
}
.main__header-left .btn.lg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  padding: 0.4rem 2rem;
}

 .main__header-left span.text1,
 .main__header-left span.text2 {
    color: rgb(255, 255, 255);
   font-size: 16px;
   animation: none;
   font-style: italic;
 }

}


/* === MEDIA QUERY FOR SCREENS SMALLER THAN 300PX */

@media screen and (max-width:300px){

  .main__header-left span.text1,
    .main__header-left span.text2 {
      color: rgb(255, 255, 255);
      font-size: 25px;
      animation: none;
      font-style: italic;
    }

}

.readMe{
  padding: 8px;
  color: rgb(73, 56, 56);
  border: 1px solid rgb(193, 193, 193);
  line-height: 5rem;
  border-radius: 5px;
}

.readMe:hover{
background-color: rgb(29, 177, 145);
color: white;
border: transparent;
}
