
/* =============FAQs===================== */

section.faqs{
  background: rgb(244, 234, 234);
  margin-top: 0;
}

article.faq{
      border: 1px solid #b3b3b3;
      background: var(--color-card-bg);
      border: 1px solid #b3b3b3;
      margin-bottom: 2rem;
}


article.faq p{
  color: var(--color-gray-100);
}
.faqs__wrapper{
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 5rem;
  align-items: flex-start;

}


/* ===========MEDIA QUERY FOR FAQS  TABLET*/

@media screen and (max-width: 1024px){
  .faqs__wrapper{
    grid-template-columns: 1fr ;
  }
}
/* ===========MEDIA QUERY FOR FAQS  MOBILE*/

@media screen and (max-width: 600px){
  .faqs__wrapper{
    gap: 1rem;
    margin-top: 3rem;

  }
}
