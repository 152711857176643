.ministers__container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  }

  /* this classes are declared in the MInister component but I'm styling them here bc im using it in the team page */

  .card.minister{
    padding: 1rem;
    /* background:rgb(58, 3, 3); */
  }

  .minister__image{
    border-radius: 0 2rem; /*we need to set overflow hiden to see it*/
    overflow: hidden;
  }

  .minister h3 {
    margin-top: 2rem;
  }

  .minister h5, p{
    margin-top: 0.8rem;
    font-size: 12px;
  }


  /* =====MEDIA QUERY TABLETs */

  @media screen and (max-width:1024px){
    .ministers__container {
        grid-template-columns:  1fr 1fr;
        gap: 2rem;
      }
  }

  /* =====MEDIA QUERY MOBILEs and small screens */

  @media screen and (max-width:600px) {
      .ministers__container {
          grid-template-columns:  1fr;
          gap: 2rem;
        }

        .card.minister{
          width: 90%;
          margin: 0 auto;
        }
  }
