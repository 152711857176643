/* I will used pseudo element to clean up my page */
/* I mean we will remove all margins, paddings, borders and oulines, so that we do not have custom styles intefering with our styling */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none; /* removes the bullet point of the li tags */
  text-decoration: none; /* removes the underlines on a tags*/
  box-sizing: border-box;/* keeps the total width of element to the given size e.g 200px will alays be 200px when it's border-box. content-box=> this will calculate the size of the content and add it to the padding, border and then calculaye the total width. */
}

/* root variables we will need */

/* colors */
/* nav, footer and buttons =  background: rgb(37, 19, 19); */

:root{
  --primary-hue: 210;
  --gray-hue: 240;
  --color-primary: hsl(var(--primary-hue), 100%, 50%); /*hsl = hue saturation lightness, hue is a bit like rgb */
  --color-primary: hsl(var(--primary-hue), 100%, 65%);


  /* the % changing are the saturation and lightnes of the colors  */
  --color-secondary: #ffb116;
  --color-main-navbar:rgb(29, 177, 145);
  --color-card-bg: rgb(42, 41, 41);
  --color-H3: #833B0B;
  --color-text-card:rgb(88, 83, 83);
  --color-secondary-CardBackground: #0939f9;
  --color-gray-100: hsl(var(--gray-hue),47%, 94%); /*white*/
  --color-gray-200: hsl(var(--gray-hue),26%, 75%);
  --color-gray-300: hsl(var(--gray-hue),24%, 64%);
  --color-gray-400: hsl(var(--gray-hue),16%, 41%);
  --color-gray-500: hsl(var(--gray-hue),44%, 25%);
  --color-gray-600: hsl(var(--gray-hue),48%, 25%);

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: all 800ms ease;

  /* line I will use as Line across in homepage */

  --line-across: 1px solid rgb(235, 233, 233);
}


/* GENERAL STYLES   FOR OUR APP */

body{
  font-family: 'Montserrat', sans-serif;
  color: rgb(67, 64, 64);
  line-height: 1.7; /* this is the height between the texts*/
  overflow-x: hidden;
  background: rgb(255, 255, 255);
  font-size: 16px;
}

/* this isi the general container class which you will find in each components */
.container{
 width: var(--container-width-lg); /*the width is 80%*/
 max-width: 1920px;
 margin-inline: auto;/*this is the same as margin: 0 auto;*/
}

h1,h3 {
  line-height: 1.2;
  /* color: var(--color-gray-100); */
  color: rgb(26, 23, 23);
}

h1{
  font-size: 3.2rem;
  color: rgb(67, 64, 64);
}

h2{
  font-size: 5rem;
}

h4,h5{
  color: rgb(255, 255, 255);
}

a {
  color: var(--color-gray-100);
}


img{
  display: block;
  object-fit: cover;
  width: 100%;/*it would take  100% of the parent width*/
}



/* below are the styling for lg buttons and sm buttons  */
.btn{
  color: white;
  width: fit-content;
  margin: 0 auto;
  padding: 0.9rem 2rem;
  background: var(--color-main-navbar);
  border-radius: 1.5rem;
  transition: var(--transition);
}

.btn:hover{
  /* background: rgb(53, 53, 231); */
  color: var(--color-gray-600);
}

.btn.lg{
  padding: .8rem 2rem;
  border-radius: 2rem;
  font-size: 1.3rem;
  color: white;
  border: 1px solid white ;
  background: transparent;
/* animation for landing page button */
  opacity: 0;
  animation: fadeInUp 1s 1s;
  animation-fill-mode: forwards;
  margin: 1rem;
}

.btn.lg:hover {
  border: 1px solid grey;
  background: var(--color-main-navbar);

}


.btn.sm{
  padding: 0.4rem 1.2rem;
  font: 0.9rem;
  background-color: transparent;
  color: var(--color-H3);
  border: 1px solid rgb(39, 36, 36);
}

.btn.sm:hover{
  background: var(--color-main-navbar);
  color: white;
  border: 1px solid var(--color-main-navbar);
  transition: var(--transition);


}


/* =====general style for reusable section head component====== */

section {
  margin-top: 4rem;
  padding-bottom: 2rem;
}


.section__head{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section__head span{
  background: var(--color-gray-500);
  padding: 0.8rem;
  border-radius: 1rem;
  color: var(--color-secondary);
  font-size: 1.5rem;
}




/* =====general style for card component====== */


.card{
  /* background: var(--color-card-bg); var(--color-gray-500) */
  border: 1px solidnone;
  text-align: center;
  color: rgb(88, 83, 83);
  /* padding: 3rem 2rem; */
  /* border-radius: 0 3rem  0 3rem ; */
  border-radius: 0.5rem;
  transition: var(--transition);
}
.card h3{
  color: #833B0B;
  margin-top: 2rem;
}

.card.minister h5{
  color: rgb(88, 83, 83);
  font-size: 14px;
}

.card small{
  /* display: flex;
  margin: 3rem;
  text-align: center;
  justify-content: center; */
  display: grid;
  margin: 1.5rem;
}

.card:hover{
  /* background: rgb(42, 1, 1); */
  /* border-color: var(--color-secondary); */
  cursor: default; /* this will make it not to point the finger when you hover on it so that i doenst look like you want to click it  */
}


/* this will target the cards that the ICONS are  wrapped with a span  and style them  */
.card span {
  width: 3rem ;
  height: 3rem;
  background: var(--color-primary);
  color: var(--color-gray-100);
  font-size: 4.5rem;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto;
  border-radius: 1rem;
  transition: var(--transition );
}

.card:hover span{
  background: var(--color-secondary);
  color: var(--color-gray-600);
}

/* .card small{
  margin-top: 1rem;
  display: block;
  font-weight: 300;
} */

.card a{
  margin-bottom: 1rem;
}


/* ====GENERAL STYLES FOR MEDIA QUERIES on TABLET   */

@media screen and (max-width: 1024px){
   .container{
    width: var(--container-width-md);
   }
   h1{
    font-size: 2rem;
   }
   h2{
    font-size: 1.6rem;
   }
}

/* ====GENERAL STYLES FOR MEDIA QUERIES on MOBILS  */

@media screen and (max-width: 600px) {

  h1{
    font-size: 2.2rem;
    line-height: 1.3rem;
  }
}


/* ====general styles for HEADER COMPONENT====== */

.header{
  margin-top: 5rem;

  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-400);
}

.header__container{
  width: 100%;
  height: 50%;
  position: relative;
  display: grid;
  place-items: center;
  background: black;
}

.header__container-bg{
  position: absolute; /*this would show the middle bit of the image bc i added position relative and display grid to the header__container*/
  width: 100% ;
}
.header__container-bg img{
  opacity: 0.5;
}

.header__content{
  position: relative; /*bring the text from the back to the front of the image*/
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: var(--color-gray-100);
}


.header__content h2{
  margin-bottom: 2rem;
}

.header__content p{
  /* color:rgba(255, 255, 255, 0.7); */
  color: #eee;
  font-size: 14px;
}

/* =====general MEDIA QUERY FOR HEADER tablet */
@media screen and (max-width: 1024px){
  .header__content{
    width: 76%;

  }
}

/* =====general MEDIA QUERY FOR HEADER mobile */
@media screen and (max-width: 600px){
  .header__content{
    width: fit-content;
  }
  section{
    margin-top: 7rem;
  }

  .header{
    height: fit-content;
  }

  .header__content{
    width: var(--container-width-md);
    padding: 3rem 0 ;
  }

  .header__content p{
    font-size: 0.85rem;
    color: rgb(255, 255, 255);
    text-align: center;
  }
}
