
.logo img{
  width: 4rem;
  border-radius: 5px ;
  display: block;
}

nav {
  height: 5rem;
  width: 100vw; /*100vw is 100% of the screen */
  background: var(--color-main-navbar);
  display: grid; /*because i want to place items in the center*/
  place-items: center;/*this will put the direct child of the nav in the middle in this case is the nav__container%*/
  position: fixed;  /*so that it does not move*/
  top: 0;
  left: 0;
  z-index: 99;/*this will make the nav sit ontop of everything on the page*/
  margin-top: 1rem;
}

/* will only be showing in tablet and mobile*/
.nav__toggle-btn {
  display: none;
}

.nav__container{
  height: 100%; /*this will be from the parent height*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nav__links{
  display: flex;
  gap: 3.5rem ;
}


/*makes transition hover effect smooth */
.nav__links a{
  transition: var(--transition);
}

.nav__links a:hover{
  color: var(--color-secondary);
}


/*this is the styling for the active nav*/
.active-nav{
  position: relative;
}

.active-nav::after{
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-main-navbar);
  position: absolute;
  left: calc(50% - 0.6rem);
  transform: rotate(45deg);
  margin-top: 0.7rem;
}

/* ===============================================================================================================*/
/* ============MEDIA QUERY NAVBAR  (it will be the only one the same for medium and small screens)===============*/
/* ================================================================================================================*/

@media screen and (max-width: 1024px){
   .nav__toggle-btn{
    display: inline-block;
    background: transparent;
    font-size: 1.8rem;
    cursor: pointer;
   }
     /*here we target svg bc the react icon is an svg  */
   .nav__toggle-btn svg{
      color: var(--color-gray-100);
   }

   .nav__links{
    position: absolute;
    top: 100%;
    right: 0;
    flex-direction: column;
    gap: 0;
    perspective: 600px;/* I do not understand it well but it adds a slower motiom to the hamburger menu like 3d effect */
   }

    .active-nav, .active-nav::after{
    display: none;
   }

   .nav__links li{
     height: 4rem;
     width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.4);/*-2rem for X axis, 2rem for Y axis, 5rem for blur, opacity black*/
    /* ====animation starts here ===*/
    animation: navAnimation 100ms ease forwards; /*keyframes for animationand the  time it last*/
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
   }

    .nav__links li:nth-child(1) {
      animation-delay: 200ms;
    }

   .nav__links li:nth-child(2){
    animation-delay: 400ms;
   }
   .nav__links li:nth-child(3){
    animation-delay:600ms;
   }
   .nav__links li:nth-child(4){
    animation-delay: 800ms;
   }
   .nav__links li:nth-child(5){
    animation-delay: 1s;
   }
   .nav__links li:nth-child(6){
    animation-delay: 1.2s;
   }
   .nav__links li:nth-child(7){
    animation-delay: 1.4s;
   }

   @keyframes navAnimation {
    to{
      transform: rotateX(0);
      opacity: 1;
    }
   }

   /* this is the styling which displays and give hambuger drop down backgrown color */

  .nav__links li a{
        background: rgb(29, 177, 145);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 5rem 0.5rem 3rem;
  }
/* ==================================== */
/* styling for adding useState Logic */
/* ===================================== */

.show__nav{
  display: flex;
}
.hide__nav{
  display: none;
}

}
