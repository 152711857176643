.navDetails {
  /* width: 100%;
  height: 50%;
  background-color: #ffffff;
  overflow: auto;
  top: 48px; */

    top: 0;
    z-index: 100;
    position: fixed;
    width: 100%;
    background-color: rgb(235, 235, 238);
    padding-bottom: 2px;
}

.navDetails p {
  float: left;
  color: rgb(8, 110, 110);
  text-decoration: none;
  font-size: 10px;
  width: 25%;
  /*divides the width in 4 equal parts*/
  text-align: center;
  margin-top: 2px;
}

.cardIcon {
  font-size: 12px;
}

/* ======MEDIA QUERY TABLETS */
@media screen and (max-width:1024px){
  .navDetails p {
    font-size: 7px;
  }
}


/* ======MEDIA QUERY MOBILES */
@media screen and (max-width:700px) {
  .navDetails p {
    font-size: 7px;
  }
}
